import { Container } from 'react-bootstrap';
const FullEmbebido = ({ hex1, hex2 }:{ hex1:string, hex2:string }) => {
	return (
    <article
        style=
        {{
            backgroundColor: hex1,
            width: "500px",
            height: "375px",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column"
        }}
    >
        <section
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <span
                style=
                {{
                    color: "white",
                    fontSize: "35px",
                    fontWeight: "900",
                }}
            >
                Rastrea tu Carga
            </span>
        </section>
        <section
            style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                width: "100%"
            }}
        >
            <input type="text" placeholder="INGRESE SU NÚMERO DE ORDEN O TRACKING"
                style={{
                    width: "80%",
                    height: "55px",
                    border: `${hex1} solid 2px`,
                    boxShadow: "2px 2px #00000059",
                    borderRadius: "10px",
                }}
            />
            <br/>
            <button
                style=
                {{
                    width: "80%",
                    height: "55px",
                    boxShadow: "2px 2px #00000059",
                    borderRadius: "10px",
                    backgroundColor: hex2,
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "900"
                }}
            >
                RASTREAR
            </button>
        </section>
        <section>
            <p style={{ color: "white", fontSize: "17px" }}>Información proporcionada por 
                <img src="https://api-dev.trackingdelpagroup.com/logo_svg.svg" alt=""/>
            </p>
        </section>
    </article>);
};

export default FullEmbebido;
