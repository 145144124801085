import instance from 'helpers/interceptor';

// export type companies = {
// 	nombre_usuario: number;
// 	empresa_usuario: string;
// 	email: string;
// 	rut_usuario: string;
// 	rut_cliente: string;
// 	nombre_fantasia: string;
// 	active: boolean;
// };

export type getNotificationsSettingAttributeRequest = {
	order_type: string;
	order_way: string | null;
};
const execute = (
	request: getNotificationsSettingAttributeRequest
): Promise<any[]> => {
	return instance
		.get('/notifications/render-data', { params: request })
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
