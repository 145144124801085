import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import TokenService from 'services/tokenService';
import loginServices, { session } from 'services/login';
import logoutServices from 'services/logout';
import { UserAtom } from 'atoms/UserAtom';

function useAuth() {
	const [userLogged, set] = useRecoilState<session | null>(UserAtom);
	const [state, setState] = useState({ loading: false, error: false });
	const [error, setError] = useState<string | undefined>(undefined);

	const login = (username: string, pass: string) => {
		setState({ loading: true, error: false });
		return loginServices({ email: username, password: pass })
			.then((session: session) => {
				setState({ loading: false, error: false });
				TokenService.setUser(session);
				set(session);
			})
			.catch((err: Error) => {
				setState({ loading: false, error: true });
				setError(err.message);
			});
	};

	const logout = () => {
		logoutServices();
		set(null);
	};

	return {
		userLogged,
		isLogged: Boolean(userLogged),
		isLoginLoading: state.loading,
		hasLoginError: state.error,
		error,
		login,
		logout,
		updateSession: set
	};
}

export { useAuth };
