import { FaEye, FaBars } from 'react-icons/fa';
import 'reactjs-popup/dist/index.css';
import '@szhsin/react-menu/dist/index.css';
import { trackingType } from 'types/trackingType';
import './PopupUserActions.css';
import { Dropdown } from 'react-bootstrap';
import PopupUpdateUserRuts from 'components/PopupUpdateUser/PopupUpdateUserRuts';
import PopupUpdateUserPassword from 'components/PopupUpdateUser/PopupUpdateUserPassword';
import PopupPermissions from 'components/PopupPermissions/PopupPermissions';
import { user } from 'services/getUser';
import PopupAudit from 'components/PopupAudit/PopupAudit';
import { PreviewUserButton } from 'components/PreviewUserButton/PreviewUserButton';
import { useAuth } from 'hooks/useAuth';
import PopupUpdateUserByAdmin from 'components/PopupUpdateUser/PopupUpdateUserByAdmin';
import ButtonExecutiveResetUserPass from 'components/ButtonExecutiveResetUserPass/ButtonExecutiveResetUserPass';
import { ExecutiveConfigNotificationButton } from 'pages/user/components/ExecutiveConfigNotificationButton';

export type PopupUserActionsProps = {
	user: user;
};

export const PopupUserActions = ({ user }: PopupUserActionsProps) => {
	const { userLogged } = useAuth();

	return (
		<Dropdown className="position-static">
			<Dropdown.Toggle variant="primary">
				<FaBars />
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item as="span">
					{userLogged?.role === 'admin' ? (
						<PopupUpdateUserByAdmin user={user} />
					) : (
						<PopupUpdateUserRuts user={user} />
					)}
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupUpdateUserPassword clientEmail={user.email} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupPermissions userID={user.id} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupAudit userID={user.id} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PreviewUserButton userID={user.id} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<ButtonExecutiveResetUserPass email={user.email} />
				</Dropdown.Item>
				{/* <Dropdown.Item as="span">
					<ExecutiveConfigNotificationButton userID={user.id} />
				</Dropdown.Item> */}
			</Dropdown.Menu>
		</Dropdown>
	);
};
