// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-card-col {
	width: calc(100% * 0.2);
}
.review-card-height {
	height: calc(100% - 1.75rem);
}

.review-big-card-green {
	background-color: #fff;
	border-left: 10px solid var(--primary-bg-color);
}

.review-big-card-red {
	background-color: #fff;
	/* border-left: 10px solid var(--danger-bg-color); */
	border-left: 10px solid red;
}

.outer {
	position: relative;
	width: 250px;
	height: 150px;
}
.canvas {
	position: absolute;
}
.percent {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	font-size: 50px;
	bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/reviewsStats/ReviewStats.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;AACxB;AACA;CACC,4BAA4B;AAC7B;;AAEA;CACC,sBAAsB;CACtB,+CAA+C;AAChD;;AAEA;CACC,sBAAsB;CACtB,oDAAoD;CACpD,2BAA2B;AAC5B;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,aAAa;AACd;AACA;CACC,kBAAkB;AACnB;AACA;CACC,kBAAkB;CAClB,SAAS;CACT,6BAA6B;CAC7B,eAAe;CACf,SAAS;AACV","sourcesContent":[".review-card-col {\r\n\twidth: calc(100% * 0.2);\r\n}\r\n.review-card-height {\r\n\theight: calc(100% - 1.75rem);\r\n}\r\n\r\n.review-big-card-green {\r\n\tbackground-color: #fff;\r\n\tborder-left: 10px solid var(--primary-bg-color);\r\n}\r\n\r\n.review-big-card-red {\r\n\tbackground-color: #fff;\r\n\t/* border-left: 10px solid var(--danger-bg-color); */\r\n\tborder-left: 10px solid red;\r\n}\r\n\r\n.outer {\r\n\tposition: relative;\r\n\twidth: 250px;\r\n\theight: 150px;\r\n}\r\n.canvas {\r\n\tposition: absolute;\r\n}\r\n.percent {\r\n\tposition: absolute;\r\n\tleft: 50%;\r\n\ttransform: translate(-50%, 0);\r\n\tfont-size: 50px;\r\n\tbottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
