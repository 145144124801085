// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eyes-content {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.eyes-content svg {
  position: absolute;
  right: 0;
}

.eyes-content span {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/PopupVisibilityColums/PopupVisibilityColums.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,QAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":["\r\n.eyes-content{\r\n    display: flex;\r\n    justify-content: start;\r\n    align-items: center;\r\n    margin-bottom: 15px;\r\n    position: relative;\r\n}\r\n\r\n.eyes-content svg{\r\n    position: absolute;\r\n    right: 0;\r\n}\r\n\r\n.eyes-content span {\r\n    margin-left: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
