// import axios from 'axios';
import { permission } from './getPermissionByUser';
import instance from "helpers/interceptor";


export type session = {
	email: string;
	api_key: string | null;
	id: number;
	country_id: number;
	// role: string;
	role: 'client' | 'admin' | 'executive';
	token: string;
	permissions: Array<permission>;
};

const execute = (data: {
	password: string;
	email: string;
}): Promise<session> => {
	return instance
		.post('/auth/login2', data)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
