// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/backgrounds/left.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-signup-header h2{color: #3b8ccb !important}
.btn-primaryLogin{
  color:#fff !important;
  background-color: #3b8ccb !important;
  border-color: #3b8ccb !important;
}
.page-login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 0 no-repeat;
  background-color: #ed2024 !important;
  background-size: contain;
  position: relative;
}
.logo-delpa-login{
  right: 40px;
  width: 30%;
}
@media only screen and (max-width: 1024px) {
  .logo-delpa-login{
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  .page-login{
    background: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/login/Login.css"],"names":[],"mappings":"AAAA,uBAAuB,yBAAyB;AAChD;EACE,qBAAqB;EACrB,oCAAoC;EACpC,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,iEAAoE;EACpE,oCAAoC;EACpC,wBAAwB;EACxB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".main-signup-header h2{color: #3b8ccb !important}\r\n.btn-primaryLogin{\r\n  color:#fff !important;\r\n  background-color: #3b8ccb !important;\r\n  border-color: #3b8ccb !important;\r\n}\r\n.page-login{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  min-height: 100vh;\r\n  background: url(../../assets/img/backgrounds/left.png) 0 0 no-repeat;\r\n  background-color: #ed2024 !important;\r\n  background-size: contain;\r\n  position: relative;\r\n}\r\n.logo-delpa-login{\r\n  right: 40px;\r\n  width: 30%;\r\n}\r\n@media only screen and (max-width: 1024px) {\r\n  .logo-delpa-login{\r\n    display: none;\r\n  }\r\n}\r\n@media only screen and (max-width: 425px) {\r\n  .page-login{\r\n    background: unset;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
