// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleEvent > span, svg{
    font-weight: 900;
}
.content{
    text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/components/PopupEvent/PopupEvent.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".titleEvent > span, svg{\r\n    font-weight: 900;\r\n}\r\n.content{\r\n    text-align: justify;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
