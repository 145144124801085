// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#form {
	width: 250px;
	margin: 0 auto;
	height: 50px;
}
#form label {
	font-size: 20px;
}

input[type='radio'] {
	display: none;
}

label {
	color: grey;
}

.clasificacion {
	direction: rtl;
	unicode-bidi: bidi-override;
	text-align: left;
}

/* label:hover,
  label:hover ~ label {
    color: orange;
  }
  
  input[type="radio"]:checked ~ label {
    color: orange;
  } */

.clasificacion > label {
	font-size: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/contactUs/ContactUsPage.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,cAAc;CACd,YAAY;AACb;AACA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,cAAc;CACd,2BAA2B;CAC3B,gBAAgB;AACjB;;AAEA;;;;;;;KAOK;;AAEL;CACC,eAAe;AAChB","sourcesContent":["#form {\r\n\twidth: 250px;\r\n\tmargin: 0 auto;\r\n\theight: 50px;\r\n}\r\n#form label {\r\n\tfont-size: 20px;\r\n}\r\n\r\ninput[type='radio'] {\r\n\tdisplay: none;\r\n}\r\n\r\nlabel {\r\n\tcolor: grey;\r\n}\r\n\r\n.clasificacion {\r\n\tdirection: rtl;\r\n\tunicode-bidi: bidi-override;\r\n\ttext-align: left;\r\n}\r\n\r\n/* label:hover,\r\n  label:hover ~ label {\r\n    color: orange;\r\n  }\r\n  \r\n  input[type=\"radio\"]:checked ~ label {\r\n    color: orange;\r\n  } */\r\n\r\n.clasificacion > label {\r\n\tfont-size: 2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
