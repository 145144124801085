import './NotificationSettings.css';
import { Container, Row, Col, Form, Button, Badge } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import getNotificationSettings from 'services/getNotificationSettings';
import IIndexable from 'types/IIndexable';
import updateNottificationSettings, {
	updateNottificationSettingsRequest,
} from 'services/updateNottificationSettings';
import { toast } from 'react-hot-toast';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import getNotificationsSettingAttribute from 'services/getNotificationsSettingAttribute';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import notificate_icon_a from './notificate_icon_a';
import notificate_icon_t from './notificate_icon_t';
import notificate_icon_m from './notificate_icon_m';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import LanguageIcons from './LanguageIcons';
import TransportTypeImage from './TransportTypeImage';
import eventDescription from './event_description';
import Tooltip from 'components/Tooltip/Tooltip';
import { useAuth } from 'hooks/useAuth';

var Spinner = require('react-spinkit');

type medio = {
	medio: 'whatsapp' | 'email';
	contact: string;
	name: string;
	language: 'english' | 'spanish';
};

type configForm = {
	events: Array<{ event_id: number; value: boolean }>;
	medios: Array<medio>;
};

// function getWay(
// 	type: 'international' | 'national' | undefined,
// 	way: 't' | 'a' | 'm' | undefined
// ) {
// 	// if (type === undefined) return 't';
// 	if (type) {
// 	}
// }

const validateEmail = (value: string) => {
	const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
	if (!re.test(value)) {
		return 'Correo electrónico inválido';
	}
	return true;
};

const NotificationSettingsPage = () => {
	const queryClient = useQueryClient();
	let { type_f, ot } = useParams();

	let [searchParams] = useSearchParams();
	const way = searchParams.get('way');


	const isPreview = searchParams.get('token') !== null;
	const { userLogged } = useAuth();


	const [medios, setMedios] = useState<Array<medio>>([]);
	const [type, setType] = useState<'international' | 'national'>(
		type_f === 'international'
			? type_f
			: type_f === 'national'
			? type_f
			: 'international'
	);
	const [transportWayState, setTransportWayState] = useState<
		't' | 'm' | 'a' | null
	>(type === 'national' ? null : way === 't' ? 't' : way === 'a' ? 'a' : 'm');

	const icons: IIndexable = {
		a: notificate_icon_a,
		t: notificate_icon_t,
		m: notificate_icon_m,
	};
	// icons[transportWayState]

	const options = [
		{ value: 'international', label: 'Internacional' },
		{ value: 'national', label: 'Nacional' },
	];

	const defaultForm: IIndexable = {};

	const {
		trigger,
		reset,
		control,
		register,
		// handleSubmit,
		getValues,
		watch,
		formState: { errors },
	} = useForm<IIndexable>({
		defaultValues: defaultForm,
	});

	const notifySettingsParams = {
		type,
		ot,
		transportWayState,
	};
	const notificationAttributeParams = {
		type_f,
		transportWayState,
	};
	const attributeQuery = useQuery({
		queryKey: ['notification_attribute', notificationAttributeParams],
		queryFn: async () => {
			toast.dismiss();
			const data = await getNotificationsSettingAttribute({
				order_type: type,
				order_way: transportWayState,
			});
			return data;
		},
		refetchOnWindowFocus: false,
		// cacheTime: 600000,
		// staleTime: 600000,
	});
	const { data, isInitialLoading, isFetching } = useQuery({
		queryKey: ['notify_settings', notifySettingsParams],
		queryFn: () =>
			getNotificationSettings({
				order_type: type,
				order: ot ?? 'general',
				order_way: transportWayState,
			}),
		onSuccess: (data) => {
			let dataIsEmpty = Object.keys(data).length < 1;
			reset(!dataIsEmpty ? JSON.parse(data.notificate_on) : defaultForm);
			setMedios(
				!dataIsEmpty
					? JSON.parse(data.contact_list).map((x: any) => ({
							medio: x.type,
							contact: x.contact,
							name: x.name,
							language: x.lang,
					  }))
					: []
			);
		},
		refetchOnWindowFocus: false,
		// cacheTime: 60000,
		// staleTime: 60000,
	});

	const events = Object.entries(attributeQuery.data ?? {}).map(
		([key, value]) => {
			return {
				key,
				name: value.label,
				active: value.active,
				can_be_confirmed: value.can_be_confirmed,
			};
		}
	);
	// const events =
	// 	type == 'international'
	// 		? notificate_on_international
	// 		: notificate_on_national;

	const [newRow, setNewRow] = useState<medio>({
		medio: 'whatsapp',
		contact: '',
		name: '',
		language: 'spanish',
	});

	const mutation = useMutation<
		void,
		Error,
		updateNottificationSettingsRequest,
		any
	>({
		mutationFn: (request) => updateNottificationSettings(request),
		onMutate: () => {
			toast.dismiss();
			const id = toast.loading('Cargando...');
			return { id };
		},
		onSuccess: (data, _, { id }) => {
			queryClient.invalidateQueries({ queryKey: ['notify_settings'] });
			toast.success('Exito', { id });
		},
		onError: (data, _, { id }) => {
			toast.error('Ocurrio un error', { id });
		},
	});

	const onSubmit = (event: any) => {
		event.preventDefault();
		let data = getValues();
		// console.log(data);
		//   };
		// const onSubmit = (data: IIndexable) => {
		let request: updateNottificationSettingsRequest = {
			order_way: transportWayState ?? 't',
			order_type: type,
			order: ot === undefined ? null : ot,
			notificate_on: Object.entries(data)
				.filter(([key]) => key !== 'phone' && key !== 'name')
				.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {}),
			contact_list: medios.map((x) => ({
				type: x.medio,
				contact: x.contact,
				name: x.name,
				lang: x.language,
			})),
		};
		Swal.fire({
			title: '¿Esta seguro de guardar los cambios?',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Confirmar',
			denyButtonText: `Cancelar`,
		}).then((result) => {
			if (result.isConfirmed) {
				mutation.mutate(request);
				// Swal.fire('Guardado!', '', 'success');
			}
			//  else if (result.isDenied) {
			// Swal.fire('Changes are not saved', '', 'info');
			// }
		});
	};
	const mediosOptions = [
		{
			label: 'Whatsapp',
			value: 'whatsapp',
		},
		{
			label: 'Correo electronico',
			value: 'email',
		},
	];

	const languageOptions = [
		// {
		// 	label: 'English',
		// 	value: 'english',
		// },
		{
			label: 'Español',
			value: 'spanish',
		},
	];

	const transportWayOptions = [
		{
			label: 'Terrestre',
			value: 't',
		},
		{
			label: 'Aéreo',
			value: 'a',
		},
		{
			label: 'Marítimo',
			value: 'm',
		},
	];

	if (
		type_f !== undefined &&
		type_f !== 'national' &&
		type_f !== 'international'
	) {
		return <Navigate to="/settings" replace={true} />;
	}

	if ((userLogged?.role == 'executive' || userLogged?.role == 'admin') && !isPreview) {
		return <Navigate to="/" replace={true} />;
	}

	return (
		<Container fluid>
			<div className="breadcrumb-header justify-content-between">
				<div className="left-content">
					<span className="main-content-title mg-b-0 mg-b-lg-1">
						Configuración general de notificaciones
					</span>
				</div>
			</div>
			<Row>
				{!type_f && (
					<Col sm="4">
						<Select
							classNamePrefix="react-select"
							placeholder="Tipo de flete"
							options={options}
							value={options.filter((c) => type == c.value)}
							onChange={(val) => {
								let value: 'international' | 'national' =
									'international';
								value =
									val?.value == 'national'
										? 'national'
										: 'international';
								setType(value);
								if (val?.value === 'national') {
									setTransportWayState(null);
									return;
								}

								setTransportWayState('m');
							}}
						/>
					</Col>
				)}
				{type === 'international' && !way && (
					<Col sm="4">
						<Select
							classNamePrefix="react-select"
							placeholder="Via de transporte"
							options={transportWayOptions}
							value={transportWayOptions.filter(
								(c) => transportWayState == c.value
							)}
							onChange={(val) => {
								let value: 't' | 'a' | 'm' =
									val?.value == 't'
										? 't'
										: val?.value == 'a'
										? 'a'
										: 'm';
								setTransportWayState(value);
							}}
						/>
					</Col>
				)}
				{type_f && (
					<Col sm="4">
						<h3>
							<Badge bg="primary">
								OT: {ot}
								{' - '}
								{type_f === 'international'
									? 'Internacional'
									: 'Nacional'}
							</Badge>
						</h3>
					</Col>
				)}
			</Row>
			{isFetching ||
				(attributeQuery.isFetching && (
					<Spinner className="main-loading" name="wandering-cubes" />
				))}
			{!isFetching && !attributeQuery.isFetching && (
				// <form onSubmit={handleSubmit(onSubmit)}>
				<form onSubmit={onSubmit}>
					<Table striped bordered>
						<thead>
							<tr className="table-secondary">
								<th colSpan={12}>
									Configurador de Notificaciones
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={4}>
									Notificar cuando el envio este:
								</td>
								<td>
									<Row>
										<Col
											sm={3}
											className="d-flex align-items-center"
										>
											<TransportTypeImage
												type={
													type == 'national'
														? 't'
														: transportWayState
												}
											/>
										</Col>
										<Col sm={9}>
											<Table striped bordered>
												<thead>
													<tr className="table-secondary">
														<th>Evento</th>
														{/* <th>Actualización de fechas</th> */}
														<th>Seleccionar</th>
													</tr>
												</thead>
												<tbody>
													{events.map((x, i) => (
														<tr key={'tr_' + i}>
															<th>
																<img
																	style={{
																		width: '25px',
																		marginRight:
																			'10px',
																	}}
																	src={
																		(type ==
																		'national'
																			? icons[
																					't'
																			  ]
																			: icons[
																					transportWayState ??
																						''
																			  ])[
																			x
																				.key
																		]
																	}
																/>
																<Tooltip
																	text={
																		eventDescription[
																			type ==
																			'national'
																				? 't'
																				: transportWayState ??
																				  ''
																		][x.key]
																	}
																	children={
																		<span>
																			{
																				x.name
																			}
																		</span>
																	}
																/>
															</th>
															{/* <th>
														{x.active && (
															<Form.Label className="custom-switch ps-0">
																<span className="custom-switch-description tx-17 me-2"></span>
																<Form.Control
																	{...register(
																		x.key +
																			'.active'
																	)}
																	disabled={
																		!x.active
																	}
																	type="checkbox"
																	className="custom-switch-input"
																/>
																<span className="custom-switch-indicator"></span>
															</Form.Label>
														)}
													</th> */}
															<th>
																{x.can_be_confirmed && (
																	<Form.Label className="custom-switch ps-0">
																		<span className="custom-switch-description tx-17 me-2"></span>
																		<Form.Control
																			{...register(
																				x.key +
																					'.confirmed'
																			)}
																			type="checkbox"
																			className="custom-switch-input"
																		/>
																		<span className="custom-switch-indicator"></span>
																	</Form.Label>
																)}
															</th>
														</tr>
													))}
												</tbody>
											</Table>
										</Col>
									</Row>
								</td>
							</tr>
							<tr>
								<td colSpan={4}>Enviar notificaciones a</td>
								<td>
									<Table striped bordered>
										<thead>
											<tr className="table-secondary">
												<th colSpan={2}>Medio</th>
												<th colSpan={3}>
													Nombre del destinatario
												</th>
												<th colSpan={3}>
													Dirección email/Teléfono
												</th>
												<th colSpan={2}>Idioma</th>
												<th colSpan={2}>Acción</th>
											</tr>
										</thead>
										<tbody>
											{medios.map((x, i) => (
												<tr key={i}>
													<td colSpan={2}>
														{
															mediosOptions.find(
																(option) =>
																	option.value ===
																	x.medio
															)?.label
														}
													</td>
													<td colSpan={3}>
														{x.name}
													</td>
													<td colSpan={3}>
														{x.contact}
													</td>
													<td colSpan={2}>
														{
															<LanguageIcons
																width="80px"
																language={
																	languageOptions.find(
																		(
																			option
																		) =>
																			option.value ===
																			x.language
																	)?.value
																}
															/>
														}
													</td>
													<td colSpan={2}>
														<Button
															variant="danger"
															disabled={isPreview}
															onClick={() => {
																setMedios(
																	(prev) => [
																		...prev.filter(
																			(
																				_,
																				i2
																			) =>
																				i2 !==
																				i
																		),
																	]
																);
															}}
														>
															<FaTrashAlt />
														</Button>
													</td>
												</tr>
											))}
												<tr>
													<td colSpan={2}>
														<Select
															classNamePrefix="react-select"
															placeholder="Medio"
															options={
																mediosOptions
															}
															value={mediosOptions.find(
																(c) =>
																	newRow.medio ===
																	c.value
															)}
															onChange={(val) =>
																setNewRow(
																	(prev) => ({
																		...prev,
																		medio:
																			val?.value ===
																			'email'
																				? 'email'
																				: 'whatsapp',
																		contact:
																			'',
																	})
																)
															}
														/>
													</td>
													<td colSpan={3}>
														<Form.Control
															{...register(
																'name'
															)}
															type="text"
															placeholder="jhon doe"
														/>
													</td>
													<td colSpan={3}>
														{newRow.medio ===
															'email' && (
															<span>
																<Form.Control
																	{...register(
																		'contact',
																		{
																			validate:
																				validateEmail,
																		}
																	)}
																	type="text"
																	placeholder="ejemplo@ejemplo.com"
																/>
																{errors.contact && (
																	<p className="error-message">
																		Correo
																		invalido
																	</p>
																)}
															</span>
														)}

														{newRow.medio ===
															'whatsapp' && (
															<span>
																<Controller
																	name="phone"
																	control={
																		control
																	}
																	rules={{
																		validate:
																			(
																				value
																			) =>
																				isValidPhoneNumber(
																					value
																				),
																	}}
																	render={({
																		field: {
																			onChange,
																			value,
																		},
																	}) => (
																		<PhoneInput
																			international
																			defaultCountry="CL"
																			className="form-control"
																			value={
																				value
																			}
																			onChange={
																				onChange
																			}
																			id="phone-input"
																		/>
																	)}
																/>
																{errors.phone && (
																	<p className="error-message">
																		Numero
																		de
																		telefono
																		invalido
																	</p>
																)}
															</span>
														)}
													</td>
													<td colSpan={2}>
														<Select
															classNamePrefix="react-select"
															placeholder="Idioma"
															options={
																languageOptions
															}
															value={languageOptions.find(
																(c) =>
																	newRow.language ===
																	c.value
															)}
															onChange={(val) =>
																setNewRow(
																	(prev) => ({
																		...prev,
																		language:
																			val?.value ===
																			'english'
																				? 'english'
																				: 'spanish',
																	})
																)
															}
														/>
													</td>
													<td colSpan={2}>
														<Button
															disabled={
																(
																	(newRow.medio ===
																	'email'
																		? watch(
																				'contact'
																		  )
																		: watch(
																				'phone'
																		  )) ??
																	''
																).match(
																	/^ *$/
																) !== null ||
																(
																	watch(
																		'name'
																	) ?? ''
																).match(
																	/^ *$/
																) !== null
															}
															onClick={async () => {
																if (
																	newRow.medio ===
																		'email' &&
																	!(await trigger(
																		'contact'
																	))
																) {
																	return;
																}

																if (
																	newRow.medio ===
																		'whatsapp' &&
																	!(await trigger(
																		'phone'
																	))
																) {
																	return;
																}

																setMedios(
																	(prev) => [
																		...prev,
																		{
																			medio: newRow.medio,
																			contact:
																				newRow.medio ===
																				'email'
																					? watch(
																							'contact'
																					  )
																					: watch(
																							'phone'
																					  ),
																			name: watch(
																				'name'
																			),
																			language:
																				newRow.language,
																		},
																	]
																);

																setTimeout(
																	() => {
																		if (
																			newRow.medio ===
																			'whatsapp'
																		) {
																			reset(
																				{
																					phone: '',
																				}
																			);
																		}

																		if (
																			newRow.medio ===
																			'email'
																		) {
																			reset(
																				{
																					contact:
																						'',
																				}
																			);
																		}

																		reset({
																			name: '',
																		});
																	},
																	10
																);
															}}
														>
															<FaPlus />
														</Button>
													</td>
												</tr>
										</tbody>
									</Table>
								</td>
							</tr>
						</tbody>
					</Table>
					<div className="d-flex justify-content-center">
						<Button type="submit">
							Guardar configuración de notificaciones
						</Button>
					</div>
				</form>
			)}
		</Container>
	);
};

export default NotificationSettingsPage;
