import axios from 'axios';
import TokenService from 'services/tokenService';
import { setRecoil } from 'recoil-nexus';
import { UserAtom } from 'atoms/UserAtom';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL+"/api",
	headers: {
		'Content-Type': 'application/json',
	},
});
instance.interceptors.request.use(
	(config) => {
		const urlParams = new URLSearchParams(window.location.search);
		const previewToken = urlParams.get('token');
		const userLogged = TokenService.getUser();

		let token: string | undefined = '';
		if (
			userLogged !== null &&
			(userLogged.role === 'admin' || userLogged.role === 'executive') &&
			previewToken !== null
		) {
			if (config.headers) config.headers['Preview'] = true;
			token = previewToken;
		} else {
			token = userLogged?.token;
		}

		// let token: string | undefined = '';
		// if (userToken != null) {
		// 	if (config.headers) config.headers['Preview'] = true;
		// 	token = userToken;
		// } else {
		// 	token = TokenService.getUser()?.token;
		// }

		if (token && config.headers) {
			config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
instance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;
		// if (err.response.status === 401 && !originalConfig._retry) {
		//     originalConfig._retry = true;

		//     console.log('logout');
		//     TokenService.removeUser();
		//     setRecoil(UserAtom, null);

		//     return Promise.reject(err);
		// }

		if (err.response.status === 401) {
			TokenService.removeUser();
			setRecoil(UserAtom, null);
		}

		return Promise.reject(err);
	}
);
export default instance;
