import instance from "helpers/interceptor";
import { getInternationalCloneResponse } from "types/getInternationalCloneResponse";

const execute = (orderID:string) : Promise<getInternationalCloneResponse> => {
    return instance.get(`/international-orders/${orderID}/clone`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;