// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.zoom {
	cursor: zoom-in;
}

.acc div a {
	padding: 12px;
	border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PopupLastMile/PopupLastMile.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,mBAAmB;CACnB,SAAS;CACT,wCAAwC;AACzC;;AAEA;CAEC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB","sourcesContent":["hr {\r\n\tmargin-top: 1rem;\r\n\tmargin-bottom: 1rem;\r\n\tborder: 0;\r\n\tborder-top: 1px solid rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.zoom {\r\n\tcursor: -webkit-zoom-in;\r\n\tcursor: zoom-in;\r\n}\r\n\r\n.acc div a {\r\n\tpadding: 12px;\r\n\tborder-radius: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
