// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContainer{
    height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

Table thead{
    position: sticky;
    top: 0;
    margin: 0;
}

.arrowPagination {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 1px gray;
}

.paginationArrows{
    float: right;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    width: 160px;
}

button.arrowPagination:disabled {
    background: rgb(211, 211, 211);
    color:black;
    border:solid 1px rgb(211, 211, 211);
    cursor: not-allowed;
}
button.arrowPagination {
    background: #0d6efd;
    color:white;
    border:solid 1px #0d6efd;
    cursor:pointer
}

.paginationSelect{
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 10px;
    background: transparent;
}
.paginationDiv{
    margin-bottom: 10px
}
.showPagination{
    border: solid 1px #ccc;
    padding: 10px;
    border-radius: 10px;
}
.resultPagination{
    margin-top: 10px;
    float: left;
}
th{
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/TableV2/BasicTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,MAAM;IACN,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,WAAW;IACX,mCAAmC;IACnC,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,wBAAwB;IACxB;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI;AACJ;AACA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".tableContainer{\r\n    height: 55vh;\r\n    overflow-y: scroll;\r\n    overflow-x: hidden;\r\n}\r\n\r\nTable thead{\r\n    position: sticky;\r\n    top: 0;\r\n    margin: 0;\r\n}\r\n\r\n.arrowPagination {\r\n    width: 35px;\r\n    height: 35px;\r\n    border-radius: 50%;\r\n    border: solid 1px gray;\r\n}\r\n\r\n.paginationArrows{\r\n    float: right;\r\n    margin-top: 10px;\r\n    display: flex;\r\n    justify-content: space-around;\r\n    width: 160px;\r\n}\r\n\r\nbutton.arrowPagination:disabled {\r\n    background: rgb(211, 211, 211);\r\n    color:black;\r\n    border:solid 1px rgb(211, 211, 211);\r\n    cursor: not-allowed;\r\n}\r\nbutton.arrowPagination {\r\n    background: #0d6efd;\r\n    color:white;\r\n    border:solid 1px #0d6efd;\r\n    cursor:pointer\r\n}\r\n\r\n.paginationSelect{\r\n    padding: 10px;\r\n    border: solid 1px #ccc;\r\n    border-radius: 10px;\r\n    background: transparent;\r\n}\r\n.paginationDiv{\r\n    margin-bottom: 10px\r\n}\r\n.showPagination{\r\n    border: solid 1px #ccc;\r\n    padding: 10px;\r\n    border-radius: 10px;\r\n}\r\n.resultPagination{\r\n    margin-top: 10px;\r\n    float: left;\r\n}\r\nth{\r\n    white-space: nowrap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
