// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
.dark-theme .calendar-table {
    background-color: #383d52 !important;
    border: 1px solid #444566 !important;
}

.dark-theme .daterangepicker {
    background-color: #2a2e3f !important;
    border: 1px solid #444566 !important;
}

.dark-theme .in-range {
    background-color: #2a2e3f !important;
    color: white !important;
}

.dark-theme .off {
    background-color: #383d52 !important;
}

.dark-theme .daterangepicker .ranges li:hover {
    background-color: transparent !important;
    color: var(--primary-bg-color);
}

.dark-theme .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: transparent !important;
    color: var(--primary-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/DatePicker.css"],"names":[],"mappings":";AACA;IACI,oCAAoC;IACpC,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,wCAAwC;IACxC,8BAA8B;AAClC;;AAEA;IACI,wCAAwC;IACxC,8BAA8B;AAClC","sourcesContent":["  \r\n.dark-theme .calendar-table {\r\n    background-color: #383d52 !important;\r\n    border: 1px solid #444566 !important;\r\n}\r\n\r\n.dark-theme .daterangepicker {\r\n    background-color: #2a2e3f !important;\r\n    border: 1px solid #444566 !important;\r\n}\r\n\r\n.dark-theme .in-range {\r\n    background-color: #2a2e3f !important;\r\n    color: white !important;\r\n}\r\n\r\n.dark-theme .off {\r\n    background-color: #383d52 !important;\r\n}\r\n\r\n.dark-theme .daterangepicker .ranges li:hover {\r\n    background-color: transparent !important;\r\n    color: var(--primary-bg-color);\r\n}\r\n\r\n.dark-theme .daterangepicker td.available:hover, .daterangepicker th.available:hover {\r\n    background-color: transparent !important;\r\n    color: var(--primary-bg-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
