import { useAuth } from 'hooks/useAuth';
import './EmbeddedPage.css'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import Prism from 'prismjs';
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min";
import 'prismjs/themes/prism.css';

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import generateApikey from 'services/generateApikey';
import { useNavigate } from 'react-router-dom';
import LiteEmbebido from './components/LiteEmbebido';
import FullEmbebido from './components/FullEmbebido';
import Swal from 'sweetalert2';
import TokenService from 'services/tokenService';
import getApikey from 'services/getApikey';
import { toast } from 'react-hot-toast';
var Spinner = require('react-spinkit');

const EmbeddedPage = () => {
	const { userLogged, updateSession } = useAuth();
	const navigate = useNavigate();
	const [liteHex, setLiteHex] = useState("#38CAB3");
	const [liteHex2, setLiteHex2] = useState("#1B3665");
	const [fullHex, setFullHex] = useState("#1b3665");

	const urlParams = new URLSearchParams(window.location.search);
	const previewToken = urlParams.get('token');
	

	const { data:dataGetApiKey, isLoading: isLoadingGetApiKey, isError: isErrorGetApiKey} = useQuery(
		['api-key'],
		() => getApikey(),
		{ 
			cacheTime: 5000,
			staleTime: 5000, 
			refetchOnWindowFocus: false,
			// enabled : previewToken == "" || previewToken == null
		}
	);

	// const { data, error, isFetching, isLoading, isError, refetch } = useQuery(
	const { data, isFetching, isError, refetch } = useQuery(
		['api-key'],
		() => generateApikey(),
		{ 
			cacheTime: 5000,
			staleTime: 5000, 
			refetchOnWindowFocus: false,
			enabled: false
			// enabled : (!isLoadingGetApiKey && !isErrorGetApiKey) || dataGetApiKey?.api_key == null
			// enabled : userLogged?.api_key == "" || userLogged?.api_key == null
		}
	);

	const ChangeApiKey = () => {
		Swal.fire({
			title: `¿Esta seguro de ${ dataGetApiKey.api_key != null ? 'sobreescribir' : 'generar' } la llave de su embebido?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar',
		  }).then((result) => {
			if (result.isConfirmed) {
				refetch().then((res)=>{
					if (userLogged == null || res.data == undefined) {
						toast.success('Ocurrio un error!');
						return;
					}

					if (previewToken != "") {
						toast.success('¡LLave generada!');
						return;
					}

					let newSession = {... userLogged};
					newSession.api_key = res.data.api_key;
					TokenService.setUser(newSession);
					updateSession(newSession);
					toast.success('¡LLave generada!');
				})
			}
		  })
	}

	useEffect(() => {
		Prism.highlightAll();
	}, [dataGetApiKey, liteHex, liteHex2, fullHex]);

	if (isLoadingGetApiKey) {
		return (<>
			<Spinner className="main-loading" name="wandering-cubes" />;
		</>)
	}

	// let api_key:string = userLogged == null ? '' : (userLogged.api_key === null ? '' : userLogged.api_key);
	// if (api_key == '') {
	// 	api_key = data === undefined ? '' : data[0].api_key;
	// }

	var api_key = dataGetApiKey.api_key;
	if (api_key == null || api_key == "") {
		return (
			<Container fluid>
				<div className="breadcrumb-header justify-content-between">
					<div className="left-content">
						<span className="main-content-title mg-b-0 mg-b-lg-1">
							EMBEBIDO
						</span>
					</div>
				</div>
				<div className='d-flex justify-content-center'>
					<Card>
						<Card.Body>
							<Card.Title>¡Genere llave por primera vez!</Card.Title>
							<Card.Text>Genere su api para poder visualizar las instrucciones del embebido, Por favor</Card.Text>
							<Button variant='primary' onClick={() => ChangeApiKey()}>
								Generar llave
							</Button>
						</Card.Body>
					</Card>
				</div>
			</Container>
		)
	}

	// if (isError || api_key == ''){
	// 	return (
	// 		<Container fluid>
	// 			<div className="breadcrumb-header justify-content-between">
	// 				<div className="left-content">
	// 					<span className="main-content-title mg-b-0 mg-b-lg-1">
	// 						EMBEBIDO
	// 					</span>
	// 				</div>
	// 			</div>
	// 			<div className='d-flex justify-content-center'>
	// 				<Card>
	// 					<Card.Body>
	// 						<Card.Title>Oooops!</Card.Title>
	// 						<Card.Text>Ocurrio un error intentando cargan la pagina, verifique su conexion a internet y reintente nuevamente</Card.Text>
	// 						<Button variant="primary" onClick={()=> navigate("/")}>Aceptar</Button>
	// 					</Card.Body>
	// 				</Card>
	// 			</div>
	// 		</Container>
	// 	)
	// }

	let lite_script = `<script defer src="${process.env.REACT_APP_API_URL+"/plugin-lite?api_key="+btoa(api_key)}&secondary_color=${liteHex.replace("#", '')}&primary_color=${liteHex2.replace("#", '')}"></script>`;
	let lite_container = `<div id="delpa_plugin_container_lite"> </div>`;

	let full_script = `<script defer src="${process.env.REACT_APP_API_URL+"/plugin-full?api_key="+btoa(api_key)}"></script>`;
	let full_container = `<div id="delpa_plugin_container_full"> </div>`;

	return (
		<Container fluid>
			<div className="breadcrumb-header justify-content-between">
				<div className="left-content">
					<span className="main-content-title mg-b-0 mg-b-lg-1">
					EMBEBIDO
					</span>
				</div>
			</div>
				<Button variant='primary' onClick={() => ChangeApiKey()}>
					Sobrescribir llave
				</Button>
			<br />
			<h6 className="mb-2 tx-12">
				EMBEBIDO LITE
			</h6>
			<Row>
				<Col sm="6">
					<input type='color' value={liteHex} onChange={e => setLiteHex(e.target.value)}/>
					<input type='color' value={liteHex2} onChange={e => setLiteHex2(e.target.value)}/>
					<p>1. Cree un nuevo archivo .html y posicione el fragmento de codigo en el lugar que prefiera.</p>
					<pre>
						<code data-prismjs-copy="Copiar" data-prismjs-copy-success="¡Copiado!" className="language-html">{lite_container}</code>
					</pre>
					<p>2. Incluye JS del embedido.</p>
					<pre>
						<code data-prismjs-copy="Copiar" data-prismjs-copy-success="¡Copiado!" className="language-html">{lite_script}</code>
					</pre>
					<p>3. Abra la página en el navegador de su elección para ver su página.</p>
				</Col>
				<Col sm="6">
					<LiteEmbebido hex1={liteHex} hex2={liteHex2}/>
				</Col>
			</Row>
			<h6 className="mb-2 tx-12">
				EMBEBIDO FULL
			</h6>
			<Row>
				<Col sm="8">
					<input type='color' value={fullHex} onChange={e => setFullHex(e.target.value)}/>
					<p>1. Cree un nuevo archivo .html y posicione el fragmento de codigo en el lugar que prefiera.</p>
					<pre>
						<code data-prismjs-copy="Copiar" data-prismjs-copy-success="¡Copiado!" className="language-html">{full_container}</code>
					</pre>
					<p>2. Incluye css del embedido.</p>
					<pre>
						<code data-prismjs-copy="Copiar" data-prismjs-copy-success="¡Copiado!" className="language-html">
							{`<link rel="stylesheet" type="text/css" href="${process.env.REACT_APP_API_URL}/plugin-full-css?primary_color=${fullHex.replace('#', '')}"></link>`}
						</code>
					</pre>
					<p>3. Incluye JS del embedido.</p>
					<pre>
						<code data-prismjs-copy="Copiar" data-prismjs-copy-success="¡Copiado!" className="language-html">{full_script}</code>
					</pre>
					<p>4. Abra la página en el navegador de su elección para ver su página.</p>
				</Col>
			</Row>
			<Row>
				<Col sm="12">
					<FullEmbebido hex={fullHex}/>
				</Col>
			</Row>
		</Container>
	  );
};

export default EmbeddedPage;
