// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.font-bold {
    font-weight: 700;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.ml-5 {
    margin-left: 1.25rem;
}
.list-disc {
    list-style-type: disc;
}`, "",{"version":3,"sources":["webpack://./src/pages/multiTracking/MultiTrackingPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".text-xl {\n    font-size: 1.25rem;\n    line-height: 1.75rem;\n}\n\n.text-3xl {\n    font-size: 1.875rem;\n    line-height: 2.25rem;\n}\n\n.font-bold {\n    font-weight: 700;\n}\n\n.text-lg {\n    font-size: 1.125rem;\n    line-height: 1.75rem;\n}\n\n.gap-2 {\n    grid-gap: 0.5rem;\n    gap: 0.5rem;\n}\n\n.ml-5 {\n    margin-left: 1.25rem;\n}\n.list-disc {\n    list-style-type: disc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
