import instance from "helpers/interceptor";
import TokenService from "./tokenService";

export type getNationalCloneResponse = {
    pais_origen: string;
    nombre_tiposervicio: string;
    razon_social: string;
    ref_clie: string;
    direccion1: string;
    direccion2: string;
    emisor: string;
    fecha: string;
    peso_carga: string;
    volumen_carga: string;
    numero_bultos: string;
    valor_flete: number;
    descripcion_carga: string;
    eta: string;
    motonave: string;
}

const execute = (orderID:string) : Promise<getNationalCloneResponse> => {
    return instance.get(`/national-orders/${orderID}/clone`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;