// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-icon {
	font-size: 4em;
	align-items: center !important;
}

.card-text {
	color: #fff;
	padding: 0.5rem;
	text-align: center;
	border-radius: 5px;
	font-size: 15px;
}
.form-control,
.btnExport,
.css-b62m3t-container {
	margin: 5px 0;
	min-height: 40px;
}

.row-container > * {
	width: calc(100% / 3);
}

.card-badge {
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/Header/Header.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,8BAA8B;AAC/B;;AAEA;CACC,WAAW;CACX,eAAe;CACf,kBAAkB;CAClB,kBAAkB;CAClB,eAAe;AAChB;AACA;;;CAGC,aAAa;CACb,gBAAgB;AACjB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".card-icon {\r\n\tfont-size: 4em;\r\n\talign-items: center !important;\r\n}\r\n\r\n.card-text {\r\n\tcolor: #fff;\r\n\tpadding: 0.5rem;\r\n\ttext-align: center;\r\n\tborder-radius: 5px;\r\n\tfont-size: 15px;\r\n}\r\n.form-control,\r\n.btnExport,\r\n.css-b62m3t-container {\r\n\tmargin: 5px 0;\r\n\tmin-height: 40px;\r\n}\r\n\r\n.row-container > * {\r\n\twidth: calc(100% / 3);\r\n}\r\n\r\n.card-badge {\r\n\tcursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
