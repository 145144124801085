// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle::after{
	content: unset !important;
}
button > svg, button > svg > path{
	cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/PopupExecutiveActions/PopupExecutiveActions.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;AACA;CACC,eAAe;AAChB","sourcesContent":[".dropdown-toggle::after{\r\n\tcontent: unset !important;\r\n}\r\nbutton > svg, button > svg > path{\r\n\tcursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
