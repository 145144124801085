import en from './icons/languages/en.png';
import es from './icons/languages/es.png';
import br from './icons/languages/br.png';

export default function LanguageIcons({
	language,
	width,
}: {
	// language: 'spanish' | 'english' | 'portuguese';
	language?: string;
	width?: string;
}) {
	if (language === 'spanish') {
		return <img src={es} width={width} />;
	}

	if (language === 'english') {
		return <img src={en} width={width} />;
	}

	if (language === 'portuguese') {
		return <img src={br} width={width} />;
	}

	return null;
}
