import Icon from '@mdi/react';
import {
	mdiViewDashboard,
	mdiEarth,
	mdiHome,
	mdiAccountMultiple,
	mdiDomain,
	mdiServer,
	mdiBellCog,
	mdiEmailArrowRight,
} from '@mdi/js';
import TokenService from 'services/tokenService';

const NotificationConfigPermissionID = 5;

function havePermission(permissionID: number) {
	let session = TokenService.getUser();
	if (!session) return false;

	let permissionFound = TokenService.getUser()?.permissions.find(
		(p) => p.id == permissionID
	);

	return !permissionFound ? false : permissionFound.active;
}

export const CLIENTMENUITEMS = () => [
	{
		menutitle: 'Trackings',
		Items: [
			{
				title: 'Dashboard',
				icon: (
					<Icon
						path={mdiViewDashboard}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',

				path: ``,
				selected: false,
				active: false,
			},
			{
				title: 'Internacional',
				icon: (
					<Icon
						path={mdiEarth}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',

				path: `international`,
				selected: false,
				active: false,
			},
			{
				title: 'Nacional',
				icon: (
					<Icon
						path={mdiHome}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `national`,
				selected: false,
				active: false,
			},
			{
				title: 'Compañias',
				icon: (
					<Icon
						path={mdiDomain}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `companies`,
				selected: false,
				active: false,
			},
			{
				title: 'Integraciones',
				icon: (
					<Icon
						path={mdiServer}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'sub',
				selected: false,
				active: false,
				comingSoon: false,
				children: [
					{
						path: `embedded`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Embebido',
					},
					{
						path: `apis`,
						type: 'link',
						active: false,
						selected: false,
						title: "Api's",
					},
					{
						path: `webhook`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Webhook',
					},
					{
						path: `middleware`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Middleware',
					},
					{
						path: `multi-tracking`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Multi tracking',
					},
				],
			},
			{
				title: 'Notificaciones',
				icon: (
					<Icon
						path={mdiBellCog}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'sub',
				selected: false,
				active: false,
				comingSoon: false,
				children: [
					havePermission(NotificationConfigPermissionID)
						? {
								path: `settings`,
								type: 'link',
								active: false,
								selected: false,
								title: 'Configuración general',
						  }
						: {
								path: `whatsapp-info`,
								type: 'link',
								active: false,
								selected: false,
								title: 'Whatsapp',
						  },
				],
			},
			// {
			// 	title: 'Contactanos',
			// 	icon: (
			// 		<Icon
			// 			path={mdiEmailArrowRight}
			// 			size={1}
			// 			className="side-menu__icon"
			// 			color="#7b8191"
			// 		/>
			// 	),
			// 	type: 'link',
			// 	path: `contactus`,
			// 	comingSoon: false,
			// 	selected: false,
			// 	active: false,
			// },
		],
	},
];

export const ADMINMENUITEMS = [
	{
		menutitle: 'Trackings',
		Items: [
			{
				title: 'Dashboard',
				icon: (
					<Icon
						path={mdiViewDashboard}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'sub',
				selected: false,
				active: false,
				comingSoon: false,
				children: [
					{
						path: `statistics`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Nro. de usuarios',
					},
					{
						path: `review-stats`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Evaluaciones',
					},
				],
			},
			{
				title: 'Ejecutivos',
				icon: (
					<Icon
						path={mdiAccountMultiple}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `/executive`,
				selected: false,
				active: false,
			},
			{
				title: 'Usuarios',
				icon: (
					<Icon
						path={mdiAccountMultiple}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `/users`,
				selected: false,
				active: false,
			},
			{
				title: 'Preguntas',
				icon: (
					<Icon
						path={mdiAccountMultiple}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `/questions`,
				selected: false,
				active: false,
			},
		],
	},
];

export const EXECUTIVEMENUITEMS = [
	{
		menutitle: 'Trackings',
		Items: [
			{
				title: 'Usuarios',
				icon: (
					<Icon
						path={mdiAccountMultiple}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `/users`,
				selected: false,
				active: false,
			},
		],
	},
];

const urlParams = new URLSearchParams(window.location.search);
const userToken = urlParams.get('token');

export const EXECUTIVEMENUITEMSPREVIEW = [
	{
		menutitle: 'Trackings',
		Items: [
			{
				title: 'Dashboard',
				icon: (
					<Icon
						path={mdiViewDashboard}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',

				path: `/dashboard`,
				params: `token=${userToken}`,
				selected: false,
				active: false,
			},
			{
				title: 'Internacional',
				icon: (
					<Icon
						path={mdiEarth}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',

				path: `international`,
				params: `token=${userToken}`,
				selected: false,
				active: false,
			},
			{
				title: 'Nacional',
				icon: (
					<Icon
						path={mdiHome}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				path: `national`,
				params: `token=${userToken}`,
				selected: false,
				active: false,
			},
			{
				title: 'Compañias',
				icon: (
					<Icon
						path={mdiDomain}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'link',
				params: `token=${userToken}`,
				path: `companies`,
				selected: false,
				active: false,
			},
			{
				title: 'Integraciones',
				icon: (
					<Icon
						path={mdiServer}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'sub',
				selected: false,
				active: false,
				comingSoon: false,
				children: [
					{
						path: `embedded`,
						type: 'link',
						active: false,
						selected: false,
						params: `token=${userToken}`,
						title: 'Embebido',
					},
					{
						path: `apis`,
						type: 'link',
						active: false,
						selected: false,
						params: `token=${userToken}`,
						title: "Api's",
					},
					{
						path: `webhook`,
						type: 'link',
						active: false,
						selected: false,
						params: `token=${userToken}`,
						title: 'Webhook',
					},
					{
						path: `middleware`,
						type: 'link',
						active: false,
						selected: false,
						params: `token=${userToken}`,
						title: 'Middleware',
					},
					{
						path: `multi-tracking`,
						type: 'link',
						active: false,
						selected: false,
						params: `token=${userToken}`,
						title: 'Multi tracking',
					},
				],
			},
			{
				title: 'Notificaciones',
				icon: (
					<Icon
						path={mdiBellCog}
						size={1}
						className="side-menu__icon"
						color="#7b8191"
					/>
				),
				type: 'sub',
				selected: false,
				active: false,
				comingSoon: false,
				children: [
					{
						params: `token=${userToken}`,
						path: `settings`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Configuración general',
					},
					{
						params: `token=${userToken}`,
						path: `email-info`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Email',
					},
					{
						params: `token=${userToken}`,
						path: `whatsapp-info`,
						type: 'link',
						active: false,
						selected: false,
						title: 'Whatsapp',
					},
				],
			},
			// {
			// 	title: 'Contactanos',
			// 	icon: (
			// 		<Icon
			// 			path={mdiEmailArrowRight}
			// 			size={1}
			// 			className="side-menu__icon"
			// 			color="#7b8191"
			// 		/>
			// 	),
			// 	type: 'link',
			// 	path: `contactus`,
			// 	comingSoon: false,
			// 	selected: false,
			// 	active: false,
			// },
		],
	},
];
