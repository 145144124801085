// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.font-bold {
    font-weight: 700;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.ml-5 {
    margin-left: 1.25rem;
}
.list-disc {
    list-style-type: disc;
}

.card-text {
    color: #000;
    padding: 0.5rem;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
}

.dark-theme .card-text {
    color: #fff;
    padding: 0.5rem;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
}

[data-copy-state] {
    background: #F7921E !important;
    color: #fff !important;
}

`, "",{"version":3,"sources":["webpack://./src/pages/embeddedPage/EmbeddedPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,sBAAsB;AAC1B","sourcesContent":[".text-xl {\r\n    font-size: 1.25rem;\r\n    line-height: 1.75rem;\r\n}\r\n\r\n.text-3xl {\r\n    font-size: 1.875rem;\r\n    line-height: 2.25rem;\r\n}\r\n\r\n.font-bold {\r\n    font-weight: 700;\r\n}\r\n\r\n.text-lg {\r\n    font-size: 1.125rem;\r\n    line-height: 1.75rem;\r\n}\r\n\r\n.gap-2 {\r\n    grid-gap: 0.5rem;\r\n    gap: 0.5rem;\r\n}\r\n\r\n.ml-5 {\r\n    margin-left: 1.25rem;\r\n}\r\n.list-disc {\r\n    list-style-type: disc;\r\n}\r\n\r\n.card-text {\r\n    color: #000;\r\n    padding: 0.5rem;\r\n    text-align: center;\r\n    border-radius: 5px;\r\n    font-size: 15px;\r\n}\r\n\r\n.dark-theme .card-text {\r\n    color: #fff;\r\n    padding: 0.5rem;\r\n    text-align: center;\r\n    border-radius: 5px;\r\n    font-size: 15px;\r\n}\r\n\r\n[data-copy-state] {\r\n    background: #F7921E !important;\r\n    color: #fff !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
