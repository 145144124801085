import instance from 'helpers/interceptor';

export type GenerateApiKeyResponse = {
    api_key: string;
}

const execute = (): Promise<GenerateApiKeyResponse> => {
	return instance
		.post(`/integrations/generate_api_key`)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
