import "./FullEmbebido.css";

const FullEmbebido = ({ hex }:{ hex:string }) => {
	return (
		<div className="divPadre">
			<span className="spanLegend" style={{color: hex}}>Información proporcionada por</span><img src="https://api-dev.trackingdelpagroup.com/logo_full.png" className="logo" alt="Logo" />
			<span className="spanTitle" style={{color: hex}}>Rastrea tu Carga</span>
			<div className="hijo">
				<input type="text" placeholder="INGRESE SU NÚMERO DE ORDEN O TRACKING" className="inputTracking" 
					style={{
						border: `2px solid ${hex}`
					}}
				/>
				<button className="inputSearch" 
				style={{
					backgroundColor: hex,
    				borderColor: hex
				}}>RASTREAR</button>
			</div>
		</div>
	  );
};

export default FullEmbebido;
