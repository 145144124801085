import { useAuth } from 'hooks/useAuth';
import {
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
	useParams,
} from 'react-router-dom';
import TokenService from 'services/tokenService';
import SignIn from '../pages/login/SignIn';
import LayoutPage from '../pages/layout/LayoutPage';
import HomePage from '../pages/home/HomePage';
import NationalPage from '../pages/national/NationalPage';
import Dashboard from '../pages/dashboard/Dashboard';
import UserPage from 'pages/user/UserPage';
import { useEffect } from 'react';
import CompaniesPage from 'pages/companies/CompaniesPage';
import ChangePassPage from 'pages/changePass/ChangePassPage';
import ExecutivePage from 'pages/executive/ExecutivePage';
import ExecutiveDashboardPage from 'pages/executive/ExecutiveDashboardPage';
import ForgotPasswordPage from 'pages/login/ForgotPasswordPage';
import EmbeddedPage from 'pages/embeddedPage/EmbeddedPage';
import ApisPage from 'pages/apis/ApisPage';
import WebhookPage from 'pages/webhook/WebhookPage';
import NotificationSettingsPage from 'pages/notificationSettings/NotificationSettingsPage';
import MiddlewarePage from 'pages/middleware/MiddlewarePage';
import MultiTrackingPage from 'pages/multiTracking/MultiTrackingPage';
import QuestionsPage from 'pages/questionsCRUD/QuestionsPage';
import ContactUsPage from 'pages/contactUs/ContactUsPage';
import ReviewStatsPage from 'pages/reviewsStats/ReviewStatsPage';
import EmailInfoPage from 'pages/notificationSettings/EmailInfoPage';
import WhatsappInfoPage from 'pages/whatsappInfo/WhatsappInfoPage';

function RequireAuth() {
	// let user = TokenService.getUser();
	const { userLogged } = useAuth();
	let location = useLocation();

	if (!userLogged) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <LayoutPage />;
}

function ValidateTokenForPreview() {
	const urlParams = new URLSearchParams(window.location.search);
	const userToken = urlParams.get('token');
	let location = useLocation();

	if (!userToken) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	return <Outlet />;
}

const routesForPreview = () => (
	<Route element={<ValidateTokenForPreview />}>
		<Route path="/dashboard" element={<Dashboard />} />
		<Route path="/international" element={<HomePage />} />
		<Route path="/national" element={<NationalPage />} />
		<Route path="/companies" element={<CompaniesPage />} />
		<Route path="/embedded" element={<EmbeddedPage />} />
		<Route path="/apis" element={<ApisPage />} />
		<Route path="/webhook" element={<WebhookPage />} />

		<Route
			path="/settings/:type_f/:ot"
			element={
				<NotificationSettingsPage
				// key={`settings/${type_f}/${ot}`}
				/>
			}
		/>
		<Route
			path="/settings"
			element={
				<NotificationSettingsPage
				// key={'settings'}
				/>
			}
		/>

		<Route path="/email-info" element={<EmailInfoPage />} />
		<Route path="/whatsapp-info" element={<WhatsappInfoPage />} />
		<Route path="/middleware" element={<MiddlewarePage />} />
		<Route path="/multi-tracking" element={<MultiTrackingPage />} />
		<Route path="/contactus" element={<ContactUsPage />} />
	</Route>
);

function MyRoutes() {
	let { type_f, ot } = useParams();

	useEffect(() => {
		const script = document.createElement('script');

		script.text =
			'!function(t){var e=t.phone,n=t.text,a=t.bottom,o=t.right,r=t.zIndex;i="b2chat-whatsapp-widget",d="ontouchstart"in document.documentElement,(s=document.createElement("a")).id=i,s.href=new URL("https://"+(d?"api":"web")+".whatsapp.com/send?phone="+e+"&text="+n).toString(),s.target="_blank",s.style.cssText="z-index:"+r+"; position: fixed; bottom: "+a+"; right: "+o;var p=document.createElement("img");function c(){var t=document.getElementById(i);t&&t.remove(),document.body.appendChild(s)}p.src="https://b2chat-filesrepo.s3-us-west-2.amazonaws.com/WhatsApp.png",p.style.cssText="width: 4.3rem",s.appendChild(p),"loading"!==document.readyState?c():window.addEventListener("DOMContentLoaded",c)}({phone:"+56937408192",text:"Hola, me gustar\xeda iniciar una conversaci\xf3n con un agente de Tracking Personalizado Delpa",bottom:"10px",right:"10px",zIndex:99999});';

		document.body.appendChild(script);
	}, []);

	const { isLogged, userLogged: user } = useAuth();
	// let user = TokenService.getUser();

	if (isLogged && user?.role === 'admin') {
		return (
			<Routes>
				<Route path="/login" element={<SignIn />} />
				<Route path="/" element={<RequireAuth />}>
					<Route index element={<Navigate to="statistics" />} />
					<Route
						path="/statistics"
						element={<ExecutiveDashboardPage />}
					/>
					<Route path="/executive" element={<ExecutivePage />} />
					<Route path="/users" element={<UserPage />} />
					<Route path="/questions" element={<QuestionsPage />} />
					<Route path="/review-stats" element={<ReviewStatsPage />} />

					{routesForPreview()}
				</Route>
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		);
	}

	if (isLogged && user?.role === 'executive') {
		return (
			<Routes>
				<Route path="/login" element={<SignIn />} />
				<Route path="/" element={<RequireAuth />}>
					<Route index element={<Navigate to="users/" />} />
					<Route path="/users" element={<UserPage />} />

					{routesForPreview()}
				</Route>
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		);
	}

	if (isLogged && user?.role === 'client') {
		return (
			<Routes>
				<Route path="/login" element={<SignIn />} />
				<Route path="/" element={<RequireAuth />}>
					<Route index element={<Dashboard />} />
					<Route path="/international" element={<HomePage />} />
					<Route path="/national" element={<NationalPage />} />
					<Route path="/companies" element={<CompaniesPage />} />
					<Route path="/embedded" element={<EmbeddedPage />} />
					<Route path="/apis" element={<ApisPage />} />
					<Route path="/webhook" element={<WebhookPage />} />

					<Route
						path="/settings/:type_f/:ot"
						element={
							<NotificationSettingsPage
								key={`settings/${type_f}/${ot}`}
							/>
						}
					/>
					<Route
						path="/settings"
						element={<NotificationSettingsPage key={'settings'} />}
					/>

					<Route path="/email-info" element={<EmailInfoPage />} />
					<Route
						path="/whatsapp-info"
						element={<WhatsappInfoPage />}
					/>
					<Route path="/middleware" element={<MiddlewarePage />} />
					<Route
						path="/multi-tracking"
						element={<MultiTrackingPage />}
					/>
					<Route path="/contactus" element={<ContactUsPage />} />
				</Route>
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		);
	}

	return (
		<Routes>
			<Route path="/login" element={<SignIn />} />
			<Route path="/forgot-password" element={<ForgotPasswordPage />} />
			<Route path="change-password" element={<ChangePassPage />} />
			<Route path="*" element={<Navigate to="login" />} />
		</Routes>
	);

	// return (
	// <Routes>
	//   <Route path="/login" element={ <SignIn /> } />
	//   <Route path="/" element={ <RequireAuth /> } >
	//      <Route path="" element={ <Dashboard /> } />
	//      <Route path="/international" index element={ <HomePage /> } />
	//      <Route path="/national" element={ <NationalPage /> } />
	//      <Route path="/companies" element={ <CompaniesPage /> } />
	//   </Route>
	// </Routes>);
}

export default MyRoutes;
