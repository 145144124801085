import instance from "helpers/interceptor"

export type insertCloneInternationalOrderBody = {
    referencia_cliente:string
    peso:string
    volumen:string
    bultos:string
    prevision_recogida:string
    prevision_entrega:string
    descripcion_carga:string
    detalle_flete:string
    motonave:string
} 

const execute = (data:insertCloneInternationalOrderBody, orderID: string) : Promise<string> => {
    return instance.post(`/national-orders/${orderID}/clone`, data)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;