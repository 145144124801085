import { FC, useState } from 'react';
import { FaEye } from 'react-icons/fa';
// import './PopupLastMile.css';
import { Button, Container, Modal } from 'react-bootstrap';

var Spinner = require('react-spinkit');

export type PopupQuantityPackagesProps = {
	text: string;
};

const PopupQuantityPackages: FC<PopupQuantityPackagesProps> = ({ text }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				style={{
					border: 'none',
					background: 'inherit',
				}}
				variant="secondary"
				size="sm"
				onClick={handleShow}
			>
				<FaEye color="black" style={{ fontSize: '25px' }} />
			</Button>

			<Modal show={show} onHide={handleClose} centered={true}>
				<Modal.Header closeButton>
					<Modal.Title>Descripción de bultos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<div>{text}</div>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default PopupQuantityPackages;
