// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.font-bold {
    font-weight: 700;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.ml-5 {
    margin-left: 1.25rem;
}
.list-disc {
    list-style-type: disc;
}`, "",{"version":3,"sources":["webpack://./src/pages/webhook/WebhookPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".text-xl {\r\n    font-size: 1.25rem;\r\n    line-height: 1.75rem;\r\n}\r\n\r\n.text-3xl {\r\n    font-size: 1.875rem;\r\n    line-height: 2.25rem;\r\n}\r\n\r\n.font-bold {\r\n    font-weight: 700;\r\n}\r\n\r\n.text-lg {\r\n    font-size: 1.125rem;\r\n    line-height: 1.75rem;\r\n}\r\n\r\n.gap-2 {\r\n    grid-gap: 0.5rem;\r\n    gap: 0.5rem;\r\n}\r\n\r\n.ml-5 {\r\n    margin-left: 1.25rem;\r\n}\r\n.list-disc {\r\n    list-style-type: disc;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
