// import axios from 'axios';
import instance from "helpers/interceptor";


export type updateUserPasswordRequest = {
	password: string;
};

const execute = (
	body: updateUserPasswordRequest,
	token: string
): Promise<void> => {
	return instance
		.post(`/users/password`, body, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
