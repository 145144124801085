import React from 'react';
import avion from './icons/AEREO/avion.jpg';
import camion from './icons/TERRESTRE/camion.jpg';
import barco from './icons/MARITIMO/barco.jpg';

function TransportTypeImage({
	type,
	className,
}: {
	type: string | null;
	className?: string;
}) {
	if (type === 't') {
		return <img src={camion} className={className} />;
	}

	if (type === 'a') {
		return <img src={avion} className={className} />;
	}

	if (type === 'm') {
		return <img src={barco} className={className} />;
	}
	return null;
}

export default TransportTypeImage;
