import instance from "helpers/interceptor";

const execute = () : Promise<any> => {
    return instance.get(`integrations/api_key`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;