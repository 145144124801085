import instance from "helpers/interceptor";
import TokenService from "./tokenService";


export type insertCloneOrderBody = {
    ref_consignatario:string
    peso:string
    volumen:string,
    bultos:string,
    prevision_entrega:string,
    detalle_flete:string,
    factura_ex:string,
    direccionRecogidaSeleccionada:string,
    direccionEntregaSeleccionada:string
} 


const execute = (data:insertCloneOrderBody, orderID: string) : Promise<string> => {
    return instance.post(`/international-orders/${orderID}/clone`, data)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;